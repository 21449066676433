import React from "react";

const FloatingBar = ({ text, button }) => {
    return (
        <div className="floating-bar show">
            <div className="floating-bar__container">
                <div className="floating-bar__container__text">
                    {text}
                </div>
                {button && (
                    <div className="floating-bar__container__buttons">
                        {button}
                    </div>
                )}
            </div>
        </div>
    )
}

export default FloatingBar