import { useState } from "react"

const MenuModalState = () => {
    const [isOpen, setIsOpen] = useState(false)

    const openMenu = () => setIsOpen(true)
    const closeMenu = () => setIsOpen(false)
    const toggleMenu = () => setIsOpen(!isOpen)

    return { isOpen, openMenu, closeMenu, toggleMenu }
}

export default MenuModalState