import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { getYouTubeEmbedUrl } from "../../../utils/gatsby-helpers";

const YoutubeVideo = ({ preview, video }) => {

    const [videoModal, setVideoModal] = useState(false)
    const [videoLoading, setVideoLoading] = useState(true)

    const openVideoModal = () => {
        setVideoModal(!videoModal)
    }

    const spinnerVideo = () => {
        setVideoLoading(!videoLoading)
    }

    return (
        <>
            <GatsbyImage alt={preview.alternativeText} image={getImage(preview.localFile)} />
            <a onClick={openVideoModal} className="button-icon" target="_blank" rel="noreferrer">
                <i className="icon-play"></i>
            </a>

            {videoModal &&
                <div className="tc-modal">
                    <div className="tc-modal__content">
                        <div className="tc-modal__content__header">
                            <button className="button-icon button-icon--sm" onClick={openVideoModal}>
                                <i className="icon-close"></i>
                            </button>
                        </div>
                        <div className="tc-modal__content__body">
                            <iframe
                                onLoad={spinnerVideo}
                                loading="lazy"
                                src={getYouTubeEmbedUrl(video)}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen="true"
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default YoutubeVideo