import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useFormInput } from "../../../utils/gatsby-helpers";
import { logoutUser, useAuthStatus } from "../../../services/google-oauth";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Cookies from 'js-cookie';
import { StaticImage } from "gatsby-plugin-image";
import useScrollListener from "../../../hooks/useScrollListener";

const ModalLogin = ({ state, plan, lang, selectedPlan }) => {
    const { executeRecaptcha } = useGoogleReCaptcha()

    const { authed, userAuthed } = useAuthStatus()

    const name = useFormInput("")
    const surname = useFormInput("")
    const email = useFormInput("")
    const locale = useFormInput(lang)
    const password = useFormInput("")
    const repassword = useFormInput("")

    const [isFormAvailable, setIsFormAvailable] = useState(true)
    const [loginError, setLoginError] = useState(null)
    const [showEmailForm, setShowEmailForm] = useState(false)
    const [showLoginForm, setShowLoginForm] = useState(false)
    const [signUpError, setSignUpError] = useState(null)

    // const [selectedPlan, setSelectedPlan] = useState(null)

    const loginElements = {
        p: ({ node, ...props }) => <p className="text--center" {...props} />,
        a: ({ node, ...props }) => <a onClick={() => {
            if (!isFormAvailable) {
                return
            }

            cleanForm()
            setShowLoginForm(true)
            setShowEmailForm(false)
        }}>{props.children}</a>
    }

    const orLoginWithElements = {
        p: ({ node, ...props }) => <span {...props} />
    }

    const signupElements = {
        p: ({ node, ...props }) => <p className="text--center" {...props} />,
        a: ({ node, ...props }) => <a onClick={() => {
            if (!isFormAvailable) {
                return
            }

            cleanForm()
            setShowLoginForm(false)
        }}>{props.children}</a>
    }

    const closeModalForm = () => {
        if (!isFormAvailable) {
            return true
        }

        // setShowModalPlanFree(false)
        state.closeMenu()
        // setShowModalPlanStarter(false)
        setShowEmailForm(false)
        setShowLoginForm(false)
        cleanForm()
        logoutUser()
    }

    const cleanForm = () => {
        name.onChange("")
        surname.onChange("")
        email.onChange("")
        password.onChange("")
        repassword.onChange("")
        setSignUpError(null)
    }

    const loginWihGoogle = async () => {
        if (!executeRecaptcha) {
            setLoginError("Execute Recaptcha fail")
            console.error("executeRecaptcha fail")
            return
        }

        setIsFormAvailable(false)

        const cookieHubspotutk = Cookies.get('hubspotutk')
        const token = await executeRecaptcha("homepage")
        const json = {
            "email": userAuthed.email,
            "google-id": userAuthed.id,
            //"g-recaptcha-response": token,
            "g-recaptcha-response": "skipr1884",
            "hubspotutk": cookieHubspotutk,
        }
        const data = JSON.stringify(json)
        const response = await fetch(process.env.ACCOUNT_LOGIN_URL, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        })
        const dataResponse = await response.json()

        if (response.ok && dataResponse.url) {
            logoutUser()
            window.location.href = dataResponse.url
            return;
        }

        setIsFormAvailable(true)
        setLoginError(dataResponse.msg)
    }

    const handleAccountGoogleLoginRedirect = (e) => {
        e.preventDefault()
        const cookieHubspotutk = Cookies.get('hubspotutk')
        window.location.href = `${process.env.ACCOUNT_GOOGLE_LOGIN_URL}?pack=${selectedPlan}&hubspotutk=${cookieHubspotutk}&locale=${locale.value}`
    }

    const handleLoginSubmit = async (e) => {
        e.preventDefault()

        setLoginError(null)

        if (password.value.length < 8) {
            setLoginError("La contraseña debe tener al menos 8 caracteres.")
            return
        }

        if (!executeRecaptcha) {
            setLoginError("Execute Recaptcha fail")
            console.error("executeRecaptcha fail")
            return
        }

        setIsFormAvailable(false)

        const cookieHubspotutk = Cookies.get('hubspotutk')
        const token = await executeRecaptcha("homepage")
        const json = {
            "email": email.value,
            "p": password.value,
            "g-recaptcha-response": token,
            //"g-recaptcha-response": "skipr1884"
            "hubspotutk": cookieHubspotutk,
        }
        const data = JSON.stringify(json)
        const response = await fetch(process.env.ACCOUNT_LOGIN_URL, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        })
        const dataResponse = await response.json()

        console.log(response, dataResponse)

        if (response.ok && dataResponse.url) {
            window.location.href = dataResponse.url
            return;
        }

        setIsFormAvailable(true)
        setLoginError(dataResponse.msg)
    }

    const handleSignUpSubmit = async (e) => {
        e.preventDefault()

        setSignUpError(null)

        if (!executeRecaptcha) {
            setSignUpError("Execute Recaptcha fail")
            console.error("executeRecaptcha fail")
            return
        }

        if (password.value.length < 8) {
            setSignUpError("La contraseña debe tener al menos 8 caracteres.")
            return
        }

        if (password.value !== repassword.value) {
            setSignUpError("Las contraseñas no coinciden.")
            return
        }

        setIsFormAvailable(false)

        const cookieHubspotutk = Cookies.get('hubspotutk')
        const token = await executeRecaptcha("homepage")
        const json = {
            "name": `${name.value} ${surname.value}`,
            "email": email.value,
            "p": password.value,
            "locale": locale.value,
            "g-recaptcha-response": token,
            //"g-recaptcha-response": "skipr1884",
            "pack": selectedPlan,
            "hubspotutk": cookieHubspotutk,
        }
        const data = JSON.stringify(json)
        const response = await fetch(process.env.ACCOUNT_SIGN_UP_URL, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        })
        const dataResponse = await response.json()

        if (response.ok && dataResponse.url) {
            window.location.href = dataResponse.url
            return
        }

        setIsFormAvailable(true)
        setSignUpError(dataResponse.msg)
    }

    useEffect(() => {
        if (authed && userAuthed) {
            if (showLoginForm) {
                loginWihGoogle()
            }/* else if(selectedPlan) {
                signUpWithGoogle()
            }*/
        }
    }, [authed, userAuthed])

    return (
        <Modal
            centered
            footer={null}
            maskClosable={false}
            onCancel={closeModalForm}
            title={<>
                <h4>{showLoginForm ? plan.title_login : plan.title}</h4>
                <ReactMarkdown children={showLoginForm ? plan.description_login : plan.description}
                    rehypePlugins={[rehypeRaw]} />
            </>}
            visible={state.isOpen}
            width={"100%"}
            wrapClassName="main__section"
        >
            <div className="ant-modal-body__thin">
                <div className="button-collapse-group">
                    <button className="button-collapse" onClick={handleAccountGoogleLoginRedirect} disabled={!isFormAvailable}>
                        <div className="button-collapse__header">
                            <StaticImage src="../images/ic_google.png" alt="Google" width={16} height={16} />
                            {plan.button_google}
                        </div>
                    </button>

                    {showLoginForm && <div className="divider divider--text"><ReactMarkdown children={plan.or_sign_up} rehypePlugins={[rehypeRaw]} components={orLoginWithElements} /></div>}

                    {!showLoginForm && <form className={`button-collapse ${showEmailForm ? 'button-collapse--expanded' : ''}`}
                        onClick={() => setShowEmailForm(true)}
                        onSubmit={handleSignUpSubmit}>
                        <div className="button-collapse__header">
                            <i className="icon-mail"></i>
                            {plan.button_email}
                        </div>

                        {showEmailForm && <div className="button-collapse__body">
                            <input className="input" type="text" required placeholder={plan.form_name} disabled={!isFormAvailable} {...name} />
                            <input className="input" type="text" required placeholder={plan.form_lastname} disabled={!isFormAvailable} {...surname} />
                            <input className="input" type="email" required placeholder={plan.form_email} disabled={!isFormAvailable} {...email} />
                            <input className="input" type="password" required placeholder={plan.form_password} disabled={!isFormAvailable} {...password} />
                            <input className="input" type="password" required placeholder={plan.form_repassword} disabled={!isFormAvailable} {...repassword} />
                            <div className="form__checkbox">
                                <div className="checkbox-group">
                                    <input className="checkbox" type="checkbox" id="checkbox_plan_free" required />
                                    <label htmlFor="checkbox_plan_free">
                                        <ReactMarkdown children={plan.form_tc_description} rehypePlugins={[rehypeRaw]} />
                                    </label>
                                </div>
                            </div>
                            <span className="fs-14 text--gray-dark"><ReactMarkdown children={plan.form_tc_info} rehypePlugins={[rehypeRaw]} /></span>

                            {signUpError && <div className="form__group form__group--vertical m-b-16">
                                <p className="text--red">{signUpError}</p>
                            </div>}

                            <div className="form__button">
                                <button type="submit" className="button button--lg" disabled={!isFormAvailable}>{plan.button_signup}</button>
                            </div>
                        </div>}
                    </form>}

                    {showLoginForm && <form onSubmit={handleLoginSubmit}>
                        <div className="form__login">
                            <input className="input" type="email" required placeholder={plan.form_email} disabled={!isFormAvailable} {...email} />
                            <input className="input" type="password" required placeholder={plan.form_password} disabled={!isFormAvailable} {...password} />
                            <div className="form__login__password">
                                <div className="checkbox-group">
                                    <input className="checkbox" type="checkbox" id="checkbox_plan_free" required />
                                    <label htmlFor="checkbox_plan_free">
                                        <ReactMarkdown children={plan.remember_me} rehypePlugins={[rehypeRaw]} />
                                    </label>
                                </div>
                                <a href="https://account.tecalis.com/password/reset">{plan.forgot_password}</a>
                            </div>

                            {loginError && <div className="form__group form__group--vertical m-b-16">
                                <p className="text--red">{loginError}</p>
                            </div>}

                            <button type="submit" className="button button--lg">{plan.button_login}</button>
                        </div>
                    </form>}

                    {!showLoginForm && <ReactMarkdown children={plan.login_text} rehypePlugins={[rehypeRaw]} components={loginElements} />}
                    {showLoginForm && <ReactMarkdown children={plan.signup_text} rehypePlugins={[rehypeRaw]} components={signupElements} />}
                </div>
            </div>
        </Modal>
    )
}

export default ModalLogin